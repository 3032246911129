import React, { useEffect, useState } from "react";
import { writeFile, utils } from "xlsx";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

// core components
import Header from "../../components/Headers/Header.js";

import componentStyles from "../../assets/theme/views/admin/tables.js";
import { withAppContext } from "../../contextApi/Auth/index.js";

import axios from "axios";
import "sweetalert2/src/sweetalert2.scss";
import { BACKEND_URL } from "../../env.js";
import { Input } from "antd";

const useStyles = makeStyles(componentStyles);

const Order = () => {
    const classes = useStyles();
    const [AllOrder, setAllOrder] = useState([]);
    const [FilteredOrders, setFilteredOrders] = useState([]);
    const [statusOptions] = useState(["pending", "shipped", "delivered", "cancelled"]);
    const [openModal, setOpenModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [openIdModal, setOpenIdModal] = useState(false);
    const [editTracking, setEditTracking] = useState(null);
    const [trackingID, setTrackingID] = useState("");
    const [fromDate, setFromDate] = useState(""); // For "From Date"
    const [toDate, setToDate] = useState(""); // For "To Date"
    const [number, setNumber] = useState("");
    const [email, setEmail] = useState("");
    const [pageNo, setPageNo] = useState(1)
    const [totalOrders, setTotalOrders] = useState(0);
    const ordersPerPage = 20;
    useEffect(() => {
        const GetAllOrder = () => {
            axios
                .get(`${BACKEND_URL}/orders`, {
                    params: { page: pageNo, limit: ordersPerPage },
                    headers: {
                        token: localStorage.getItem("Token"),
                    },
                })
                .then((res) => {
                    console.log(res.data, "order");
                    setAllOrder(res?.data?.orders);
                    setFilteredOrders(res?.data?.orders);
                    setTotalOrders(res?.data?.totalOrders)
                })
                .catch((err) => console.log(err));
        };
        GetAllOrder();
    }, [pageNo]);
    const handlePageChange = (event, value) => {
        setPageNo(value);
    };
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    };

    const handleStatusChange = (orderId, newStatus) => {
        axios
            .put(
                `${BACKEND_URL}/orders/${orderId}`,
                { status: newStatus },
                {
                    headers: {
                        token: localStorage.getItem("Token"),
                    },
                }
            )
            .then((res) => {
                setAllOrder((prevOrders) =>
                    prevOrders.map((order) =>
                        order.id === orderId ? { ...order, status: newStatus } : order
                    )
                );
            })
            .catch((err) => console.log(err));
    };

    const handleViewProductClick = (products) => {
        console.log(products, "orderproduct");

        setSelectedProduct(products);
        setOpenModal(true);
    };
    const handleOpenIdModal = (id) => {
        console.log(id, "orderproduct");

        setEditTracking(id);
        setOpenIdModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedProduct(null);
    };
    const handleIdCloseModal = () => {
        setOpenIdModal(false);
        setEditTracking(null);
        setTrackingID("")
    };
    const handleSubmitId = () => {
        axios
            .put(
                `${BACKEND_URL}/orders/${editTracking}`,
                { trackingID: trackingID },
                {
                    headers: {
                        token: localStorage.getItem("Token"),
                    },
                }
            )
            .then((res) => {
                // if(res?.id){
                window.location.reload()
                // }
                setAllOrder((prevOrders) =>
                    prevOrders.map((order) =>
                        order.id === editTracking ? { ...order, trackingID: trackingID } : order
                    )
                );
            })
            .catch((err) => console.log(err));
    };

    // Handle Date Filter
    const handleFilter = () => {
        const from = new Date(fromDate).setHours(0, 0, 0, 0);
        const to = new Date(toDate).setHours(23, 59, 59, 999);

        const filtered = AllOrder.filter((order) => {
            const orderDate = new Date(order.orderDate).getTime();
            const isDateValid = (!fromDate || orderDate >= from) && (!toDate || orderDate <= to);
            const isNumberValid = !number || order.phoneNumber?.includes(number); // Check if phone number includes the entered number
            const isEmailValid = !email || order.email?.includes(email); // Check if phone number includes the entered number
            return isDateValid && isNumberValid && isEmailValid;
        });

        setFilteredOrders(filtered);
    };

    const handleClearFilter = () => {
        setFilteredOrders(AllOrder);
        setFromDate("");
        setToDate("");
        setNumber("")
    };
    const handleDownloadExcel = () => {
        // Prepare the data for Excel
        const data = [];

        FilteredOrders.forEach((order) => {
            if (order.products && order.products.length > 0) {
                order.products.forEach((product) => {
                    data.push({
                        "Order ID": order.orderId,
                        "Order Date": formatDate(order.orderDate),
                        "StoreName": order.user.storeName,
                        "Email": order.email,
                        "Phone Number": order.phoneNumber,
                        "Product ID": product.id,
                        "Product Name": product.title,
                        "Product Quantity": product.orderproduct.quantity,
                        "Product Price": product.price,
                        "Name": `${order?.firstName} ${order?.lastName}`,
                        "address": `${order?.address1}`,
                    });
                });
            } else {
                data.push({
                    "Order ID": order.orderId,
                    "Order Date": formatDate(order.orderDate),
                    "StoreName": order.user.storeName,
                    "Email": order.email,
                    "Phone Number": order.phoneNumber,
                    "Profit Margin": order.profileMargin || 0,
                    "City": order.city,
                    "Total Amount": order.totalAmount,
                });
            }
        });

        // Create a worksheet and workbook
        const worksheet = utils.json_to_sheet(data);
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, "Orders");

        const from = fromDate ? formatDate(fromDate) : "Start";
        const to = toDate ? formatDate(toDate) : "End";
        const fileName = `Orders_${from}_to_${to}.xlsx`;

        // Trigger download
        writeFile(workbook, fileName);
    };

    // const handleDownloadExcel = () => {
    //     // Prepare the data for Excel
    //     const data = FilteredOrders.map((order) => ({
    //         "Order ID": order.orderId,
    //         "Order Date": formatDate(order.orderDate),
    //         "Email": order.email,
    //         "Phone Number": order.phoneNumber,
    //         "Profit Margin": order.profitMargin || 0,
    //         "City": order.city,
    //         "Total Amount": order.totalAmount,
    //         "Products Count": order.products?.length || 0,
    //         "Status": order.status,
    //     }));

    //     // Create a worksheet and workbook
    //     const worksheet = utils.json_to_sheet(data);
    //     const workbook = utils.book_new();
    //     utils.book_append_sheet(workbook, worksheet, "Orders");

    //     // Trigger download
    //     writeFile(workbook, "FilteredOrders.xlsx");
    // };
    return (
        <>
            <Header />
            <Container
                maxWidth={false}
                component={Box}
                marginTop="-6rem"
                classes={{ root: classes.containerRoot }}
            >
                <Card classes={{ root: classes.cardRoot }}>
                    <CardHeader
                        className={classes.cardHeader}
                        title="Available Orders"
                        titleTypographyProps={{
                            component: Box,
                            marginBottom: "0!important",
                            variant: "h3"
                        }}
                    />
                    <Box display="flex" justifyContent="space-between" alignItems="center" padding="1rem" flexWrap="wrap" gridGap={10}>
                        <TextField
                            label="From Date"
                            type="date"
                            value={fromDate}
                            onChange={(e) => setFromDate(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            label="To Date"
                            type="date"
                            value={toDate}
                            onChange={(e) => setToDate(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            label="Number"
                            placeholder="Enter A Number"
                            type="text"
                            value={number}
                            onChange={(e) => setNumber(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            label="Email"
                            placeholder="Enter A Email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                        />
                        <Button variant="contained" color="primary" onClick={handleFilter}>
                            Apply Filter
                        </Button>
                        <Button variant="contained" color="secondary" onClick={handleClearFilter}>
                            Clear Filter
                        </Button>
                        <Button
                            variant="contained"
                            color="default"
                            onClick={handleDownloadExcel}
                        >
                            Download Excel
                        </Button>
                    </Box>
                    {FilteredOrders && FilteredOrders?.length >= 1 ? (
                        <TableContainer>
                            <Box component={Table} alignItems="center" marginBottom="0!important">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableCellRoot + " " + classes.tableCellRootHead}>Order ID</TableCell>
                                        <TableCell className={classes.tableCellRoot + " " + classes.tableCellRootHead}>Order Date</TableCell>
                                        <TableCell className={classes.tableCellRoot + " " + classes.tableCellRootHead}>Email</TableCell>
                                        <TableCell className={classes.tableCellRoot + " " + classes.tableCellRootHead}>Name</TableCell>
                                        <TableCell className={classes.tableCellRoot + " " + classes.tableCellRootHead}>Store Name</TableCell>
                                        <TableCell className={classes.tableCellRoot + " " + classes.tableCellRootHead}>Address</TableCell>
                                        <TableCell className={classes.tableCellRoot + " " + classes.tableCellRootHead}>Phone Number</TableCell>
                                        <TableCell className={classes.tableCellRoot + " " + classes.tableCellRootHead}>Profit Margin</TableCell>
                                        <TableCell className={classes.tableCellRoot + " " + classes.tableCellRootHead}>City</TableCell>
                                        <TableCell className={classes.tableCellRoot + " " + classes.tableCellRootHead}>Total Amount</TableCell>
                                        <TableCell className={classes.tableCellRoot + " " + classes.tableCellRootHead}>Tracking ID</TableCell>
                                        <TableCell className={classes.tableCellRoot + " " + classes.tableCellRootHead}>Add Tracking ID</TableCell>
                                        <TableCell className={classes.tableCellRoot + " " + classes.tableCellRootHead}>Products</TableCell>
                                        <TableCell className={classes.tableCellRoot + " " + classes.tableCellRootHead}>Status</TableCell>
                                        <TableCell className={classes.tableCellRoot + " " + classes.tableCellRootHead}>View Product</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {FilteredOrders.sort((a, b) => new Date(b.orderDate) - new Date(a.orderDate))?.map((order) => {
                                        console.log(order);

                                        return (
                                            <TableRow key={order.orderId}>
                                                <TableCell className={classes.tableCellRoot + " " + classes.tableCellRootBodyHead}>
                                                    <Box alignItems="center" display="flex">
                                                        <Box display="flex" alignItems="flex-start">
                                                            <Box fontSize=".875rem" component="span">{order.orderId}</Box>
                                                        </Box>
                                                    </Box>
                                                </TableCell>
                                                <TableCell className={classes.tableCellRoot}>
                                                    <Box paddingTop=".35rem" paddingBottom=".35rem">{formatDate(order?.orderDate)}</Box>
                                                </TableCell>
                                                <TableCell className={classes.tableCellRoot}>
                                                    <Box paddingTop=".35rem" paddingBottom=".35rem">{order.email}</Box>
                                                </TableCell>
                                                <TableCell className={classes.tableCellRoot}>
                                                    <Box paddingTop=".35rem" paddingBottom=".35rem">{order.firstName} {order.lastName}</Box>
                                                </TableCell>
                                                <TableCell className={classes.tableCellRoot}>
                                                    <Box paddingTop=".35rem" paddingBottom=".35rem">{order?.user?.storeName || "-"}</Box>
                                                </TableCell>
                                                <TableCell className={classes.tableCellRoot}>
                                                    <Box paddingTop=".35rem" paddingBottom=".35rem">{order.address1}</Box>
                                                </TableCell>
                                                <TableCell className={classes.tableCellRoot}>
                                                    <Box paddingTop=".35rem" paddingBottom=".35rem">{order.phoneNumber}</Box>
                                                </TableCell>
                                                <TableCell className={classes.tableCellRoot}>
                                                    <Box paddingTop=".35rem" paddingBottom=".35rem">{order.profileMargin || 0}</Box>
                                                </TableCell>
                                                <TableCell className={classes.tableCellRoot}>
                                                    <Box paddingTop=".35rem" paddingBottom=".35rem">{order.city}</Box>
                                                </TableCell>
                                                <TableCell className={classes.tableCellRoot}>
                                                    <Box paddingTop=".35rem" paddingBottom=".35rem">{order.totalAmount}</Box>
                                                </TableCell>
                                                <TableCell className={classes.tableCellRoot}>
                                                    <Box paddingTop=".35rem" paddingBottom=".35rem">{order?.trackingID || "-"}</Box>
                                                </TableCell>
                                                <TableCell className={classes.tableCellRoot}>
                                                    <Box paddingTop=".35rem" paddingBottom=".35rem">
                                                        <Button onClick={() => handleOpenIdModal(order.id)}>Add Tracking ID</Button>
                                                    </Box>
                                                </TableCell>
                                                <TableCell className={classes.tableCellRoot}>
                                                    <Box paddingTop=".35rem" paddingBottom=".35rem">{order.products?.length}</Box>
                                                </TableCell>
                                                <TableCell className={classes.tableCellRoot}>
                                                    <Select
                                                        value={order.status}
                                                        onChange={(e) => handleStatusChange(order.id, e.target.value)}
                                                        variant="outlined"
                                                        fullWidth
                                                    >
                                                        {statusOptions.map((status) => (
                                                            <MenuItem key={status} value={status}>
                                                                {status}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </TableCell>
                                                <TableCell className={classes.tableCellRoot}>
                                                    <Box paddingTop=".35rem" paddingBottom=".35rem">
                                                        <Button onClick={() => handleViewProductClick(order.products)}>View Product</Button>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Box>
                        </TableContainer>
                    ) : (
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                alignContent: "center",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                        >
                            <h1>No Order to Show</h1>
                        </div>
                    )}
                    <Box
                        classes={{ root: classes.cardActionsRoot }}
                        component={CardActions}
                        justifyContent="flex-end"
                    >
                        <Pagination
                            count={Math.ceil(totalOrders / ordersPerPage)}
                            page={pageNo}
                            onChange={handlePageChange}
                            color="primary"
                            variant="outlined"
                        />
                    </Box>
                </Card>

                {/* Modal for viewing products */}
                <Dialog open={openModal} onClose={handleCloseModal} maxWidth="md" fullWidth>
                    <DialogTitle>Order Products</DialogTitle>
                    <DialogContent>
                        {selectedProduct ? (
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>ID</TableCell>
                                            <TableCell>Product Name</TableCell>
                                            <TableCell>Quantity</TableCell>
                                            <TableCell>Price</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {selectedProduct.map((product) => {
                                            console.log(product.orderproduct);

                                            return (
                                                <TableRow key={product.id}>
                                                    <TableCell>{product.id}</TableCell>
                                                    <TableCell>{product.title}</TableCell>
                                                    <TableCell>{product.orderproduct.quantity}</TableCell>
                                                    <TableCell>{product.price}</TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <p>No products to display</p>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseModal} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={openIdModal} onClose={handleIdCloseModal} maxWidth="xs" fullWidth>
                    <DialogTitle>Tracking ID</DialogTitle>
                    <DialogContent>
                        <Input placeholder="Tracking Id" value={trackingID} onChange={(e) => setTrackingID(e.target.value)} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleIdCloseModal} color="primary">
                            Close
                        </Button>
                        <Button onClick={handleSubmitId} color="success">
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </>
    );
};

export default withAppContext(Order);
