// @material-ui/icons components
import CategoryIcon from '@material-ui/icons/Category';
import StoreIcon from '@material-ui/icons/Store';
import LabelIcon from '@material-ui/icons/Label';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import InstagramIcon from '@material-ui/icons/Instagram';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
// core components
import CategoryTable from "./views/admin/Category";
import Products from "./views/admin/Products";
import SubCategory from "./views/admin/SubCategory";
import Login from "./views/auth/Login";
// import AddProduct from "./views/admin/CreateProduct";
import CreateProduct from "./views/admin/createProduct/CreateProduct";
import Tags from "./views/admin/Tags";
import User from "./views/admin/User";
import Slider from "./views/admin/Slider";
import Instagram from "./views/admin/Instagram";
import Order from "./views/admin/Order";
import Reward from './views/admin/Reward';
import TransactionHistory from './views/admin/TransactionHistory';
const routes = [
  {
    path: "/index",
    name: "Products",
    icon: StoreIcon,
    iconColor: "Error",
    component: Products,
    layout: "/admin",
  },
  {
    path: "/Category",
    name: "Category",
    icon: CategoryIcon,
    iconColor: "Error",
    component: CategoryTable,
    layout: "/admin",
  },
  // {
  //   path: "/SubCategory",
  //   name: "SubCategory",
  //   icon: CategoryIcon,
  //   iconColor: "Error",
  //   component: SubCategory,
  //   layout: "/admin",
  // },
  {
    path: "/Tag",
    name: "Tags",
    icon: LabelIcon,
    iconColor: "Error",
    component: Tags,
    layout: "/admin",
  },
  {
    path: "/User",
    name: "Users",
    icon: AccountCircleIcon,
    iconColor: "Error",
    component: User,
    layout: "/admin",
  },
  {
    path: "/Slider",
    name: "Slider",
    icon: LinearScaleIcon,
    iconColor: "Error",
    component: Slider,
    layout: "/admin",
  },
  {
    path: "/Instagram",
    name: "Instagram Gallery",
    icon: InstagramIcon,
    iconColor: "Error",
    component: Instagram,
    layout: "/admin",
  },
  {
    path: "/Order",
    name: "Orders",
    icon: BookmarkBorderIcon,
    iconColor: "Error",
    component: Order,
    layout: "/admin",
  },
  {
    path: "/Reward",
    name: "Rewards",
    icon: CardGiftcardIcon,
    iconColor: "Error",
    component: Reward,
    layout: "/admin",
  },
  {
    path: "/TransactionHistory",
    name: "Transaction History",
    icon: AccountBalanceWalletIcon,
    iconColor: "Error",
    component: TransactionHistory,
    layout: "/admin",
  },
  {
    path: "/login",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/addProduct",
    component: CreateProduct,
    layout: "/admin",
  },
];
export default routes;
