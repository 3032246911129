import axios from "axios";
import React, { createContext, useState, useEffect } from "react";
import { BACKEND_URL } from "../../env";
// import { io } from "socket.io-client";
import { toast } from "react-toastify";
import Swal from "sweetalert2/dist/sweetalert2.js";

export const AppContext = createContext();
export const withAppContext = (Component) => (props) =>
(
  <AppContext.Consumer>
    {(value) => <Component {...value} {...props} />}
  </AppContext.Consumer>
);

const AppProvider = ({ children }) => {
  const token = localStorage.getItem("Token");
  console.log(token, "token");
  const [AllCategory, setAllCategory] = useState([]);
  const [AllSlider, setAllSlider] = useState([]);
  const [AllInstagram, setAllInstagram] = useState([]);
  const [AllMultipleProducts, setAllMultipleProducts] = useState([]);
  const [GetToken, setGetToken] = useState(null);
  const [refresh, setRefresh] = useState(false);

  function GetLogin(Obj, setLoading, history) {
    const config = {
      method: "post",
      url: `${BACKEND_URL}/auth/adminlogin`,
      data: Obj,
    };
    axios(config)
      .then((res) => {
        // console.log(res, "res");
        if (res.data.token) {
          setGetToken(res.data.token);
          console.log(res.data, "res");
          localStorage.setItem("Token", res.data.token);
          history.push("/admin/index");
          setLoading(false);
          toast.success("Login Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
          });
        } else {
          // 	// Swal.fire({
          // 	// 	title: "Are you sure?",
          // 	// 	text: "Your Credintials Are not Correct!",
          // 	// 	icon: "warning",
          // 	// 	showCancelButton: true,
          // 	// 	confirmButtonColor: "#3085d6",
          // 	// 	cancelButtonColor: "#d33",
          // 	// 	confirmButtonText: "Retry"
          // 	// });
          toast.error(
            res?.data?.message ? res?.data?.message : "Invalid error",
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
            }
          );
          setLoading(false);
        }
      })
      .catch((res) => {
        console.log(res, "res");
        Swal.fire({
          // title: "Are you sure?",
          text: "Your Credintials Are not Correct!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Retry",
        });
        setLoading(false);
      });
  }
  function GetAllCategories() {
    axios
      .get(`${BACKEND_URL}/categories`, {
        headers: {
          token: localStorage.getItem("Token"),
        },
      })
      .then((res) => {
        console.log(res.data?.getAllCategories, "resp");

        setAllCategory(res?.data?.getAllCategories);
      })
      .catch((res) => console.log(res));
  }
  function GetAllSliders() {
    axios
      .get(`${BACKEND_URL}/slider`, {
        headers: {
          token: localStorage.getItem("Token"),
        },
      })
      .then((res) => {
        console.log(res.data?.getAllSliders, "resp");

        setAllSlider(res?.data?.getAllSliders);
      })
      .catch((res) => console.log(res));
  }
  function GetAllInstagram() {
    axios
      .get(`${BACKEND_URL}/instagram`, {
        headers: {
          token: localStorage.getItem("Token"),
        },
      })
      .then((res) => {
        console.log(res.data?.getAllInstagrams, "resp");

        setAllInstagram(res?.data?.getAllInstagrams);
      })
      .catch((res) => console.log(res));
  }
  function GetAllMultipleProducts() {
    axios
      .get(`${BACKEND_URL}/filterProduct`, {
        headers: {
          token: localStorage.getItem("Token"),
        },
      })
      .then((res) => {
        setAllMultipleProducts(res.data);
      })
      .catch((res) => console.log(res));
  }

  useEffect(() => {
    GetAllCategories();
  }, [!refresh]);

  const [AllProducts, setAllProducts] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  function GetAllProducts({ page, limit }) {
    axios
      .get(`${BACKEND_URL}/products`, {
        params: { page, limit },
        headers: {
          token: localStorage.getItem("Token"),
        },
      })
      .then((res) => {
        // console.log(res.data.getAllProducts, "hello");

        setAllProducts(res.data.getAllProducts);
        setTotalProducts(res.data.totalProducts);
      })
      .catch((res) => console.log(res));
  }

  // useEffect(() => {
  // 	GetAllProducts();
  // }, [!refresh]);

  // const socket = io(`${BACKEND_URL}`);
  // useEffect(() => {
  //   socket.on("connect", () => {
  //     AllProducts.map((pro, i) => {
  //       socket.on(`Quantity-${pro.id}`, (msg) => {
  //         pro.QuantityAvailable = msg;
  //         AllProducts.filter((a) => {
  //           if (a.id == pro.id) {
  //             a.QuantityAvailable = msg;
  //           }
  //           setAllProducts(AllProducts);
  //         });
  //       });
  //     });
  //   });
  // }, [socket]);

  //Tags
  const [addTagLoading, setAddTagLoading] = useState([]);
  const [TagsData, setTagsData] = useState([]);
  const [addTagsLoading, setAddTagsLoading] = useState([]);
  function getTags() {
    setAddTagsLoading(true);
    axios
      .get(`${BACKEND_URL}/tag`, {
        headers: {
          token: localStorage.getItem("Token"),
        },
      })
      .then((data) => {
        console.log(data, "no");

        setTagsData(data?.data?.getAllTags);
        setAddTagsLoading(false);
      })
      .catch(() => {
        setAddTagsLoading(false);
      });
  }
  const [UserData, setUserData] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [addUserLoading, setAddUserLoading] = useState([]);
  function getUser({ page, limit }) {
    setAddUserLoading(true);
    axios
      .get(`${BACKEND_URL}/users`, {
        params: { page, limit },
        headers: {
          token: localStorage.getItem("Token"),
        },
      })
      .then((data) => {
        console.log(data, "no");

        setUserData(data?.data?.users);
        setTotalUsers(data?.data?.totalUsers)
        setAddUserLoading(false);
      })
      .catch(() => {
        setAddUserLoading(false);
      });
  }
  // useEffect(() => {
  //   console.log(TagsData,"TagsDataTagsDataTagsData");
  // }, [TagsData]);
  return (
    <AppContext.Provider
      value={{
        token,
        totalUsers,
        AllCategory,
        GetAllCategories,
        GetAllMultipleProducts,
        AllMultipleProducts,
        GetAllProducts,
        GetLogin,
        GetToken,
        TagsData,
        addTagLoading,
        AllProducts,
        refresh,
        setRefresh,
        addTagsLoading,
        getTags,
        getUser,
        UserData,
        addUserLoading,
        GetAllSliders,
        AllSlider,
        AllInstagram,
        GetAllInstagram,
        totalProducts,
        AllCategory
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
