import React, { useEffect, useState } from "react";

// @material-ui/core components
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import { Input } from "antd";

import Header from "../../components/Headers/Header.js";
import componentStyles from "../../assets/theme/views/admin/tables.js";
import { withAppContext } from "../../contextApi/Auth/index.js";

const useStyles = makeStyles(componentStyles);

const User = ({ getUser, UserData, totalUsers }) => {
    const classes = useStyles();
    const [pageNo, setPageNo] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");

    const usersPerPage = 20;

    useEffect(() => {
        getUser({ page: pageNo, limit: usersPerPage });
    }, [pageNo]);

    const handlePageChange = (event, value) => {
        setPageNo(value);
    };

    const filteredUsers = UserData.filter((user) =>
        user.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <>
            <Header />
            <Container
                maxWidth={false}
                component={Box}
                marginTop="-6rem"
                classes={{ root: classes.containerRoot }}
            >
                <Card classes={{ root: classes.cardRoot }}>
                    <CardHeader
                        className={classes.cardHeader}
                        title="Available Users"
                        titleTypographyProps={{
                            component: Box,
                            marginBottom: "0!important",
                            variant: "h3"
                        }}
                        action={
                            <Box display="flex" alignItems="center">
                                <Input
                                    placeholder="Filter by Email"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    allowClear
                                />
                            </Box>
                        }
                    ></CardHeader>

                    <TableContainer>
                        <Box component={Table} alignItems="center" marginBottom="0!important">
                            <TableHead>
                                <TableRow>
                                    <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                                        ID
                                    </TableCell>
                                    <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                                        Email
                                    </TableCell>
                                    <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                                        Whatsapp
                                    </TableCell>
                                    <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                                        Store Name
                                    </TableCell>
                                    <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                                        CNIC
                                    </TableCell>
                                    <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                                        Bank Name
                                    </TableCell>
                                    <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                                        Account Title
                                    </TableCell>
                                    <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                                        Account Number
                                    </TableCell>
                                    <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                                        IBAN
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredUsers.length > 0 ? (
                                    filteredUsers.map((a, i) => (
                                        <TableRow key={a.id}>
                                            <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootBodyHead }}>
                                                <Box alignItems="center" display="flex">
                                                    <Box fontSize=".875rem" component="span">
                                                        {a.id}
                                                    </Box>
                                                </Box>
                                            </TableCell>
                                            <TableCell classes={{ root: classes.tableCellRoot }}>
                                                {a?.email}
                                            </TableCell>
                                            <TableCell classes={{ root: classes.tableCellRoot }}>
                                                {a?.whatsapp}
                                            </TableCell>
                                            <TableCell classes={{ root: classes.tableCellRoot }}>
                                                {a?.storeName}
                                            </TableCell>
                                            <TableCell classes={{ root: classes.tableCellRoot }}>
                                                {a?.cnic}
                                            </TableCell>
                                            <TableCell classes={{ root: classes.tableCellRoot }}>
                                                {a?.bankNumber}
                                            </TableCell>
                                            <TableCell classes={{ root: classes.tableCellRoot }}>
                                                {a?.accountTitle}
                                            </TableCell>
                                            <TableCell classes={{ root: classes.tableCellRoot }}>
                                                {a?.accountNumber}
                                            </TableCell>
                                            <TableCell classes={{ root: classes.tableCellRoot }}>
                                                {a?.iban}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={9} align="center">
                                            No Users Found
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Box>
                    </TableContainer>

                    <Box
                        classes={{ root: classes.cardActionsRoot }}
                        component={CardActions}
                        justifyContent="flex-end"
                    >
                        <Pagination
                            count={Math.ceil(totalUsers / usersPerPage)}
                            page={pageNo}
                            onChange={handlePageChange}
                            color="primary"
                            variant="outlined"
                        />
                    </Box>
                </Card>
            </Container>
        </>
    );
};

export default withAppContext(User);
