import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
// @material-ui/icons components
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
// core components
import Header from "../../components/Headers/Header.js";

import componentStyles from "../../assets/theme/views/admin/tables.js";
import { withAppContext } from "../../contextApi/Auth/index.js";
import { CardContent, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { BACKEND_URL } from "../../env.js";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import { CloudUpload } from "@material-ui/icons";
import { toast } from "react-toastify";

const useStyles = makeStyles(componentStyles);

const ProductsTable = ({ AllProducts, GetAllProducts, totalProducts, AllCategory }) => {
	const classes = useStyles();
	const history = useHistory();
	const [pageNo, setPageNo] = useState(1)
	// const [totalProducts, setTotalProducts] = useState(0);
	const [products, setProducts] = useState([])
	const [pagination, setPagination] = useState(true)
	const [openModel, setOpenModel] = useState(false)
	const [csvFile, setCsvFile] = useState(null);
	const [selectedCategory, setSelectedCategory] = useState("");
	const productsPerPage = 50;
	// useEffect(() => {
	// 	console.log('runn')
	// 	GetAllProducts({ page: pageNo, limit: productsPerPage });
	// }, []);
	useEffect(() => {
		GetAllProducts({ page: pageNo, limit: productsPerPage });
	}, [pageNo]);
	useEffect(() => {
		// setPageNo(1)
		// setPagination(true)
		setProducts(AllProducts)
	}, [AllProducts])

	const handleFileChange = (e) => {
		setCsvFile(e.target.files[0]);
	};

	const handleUpload = async () => {
		if (!csvFile) {
			toast.error("Please select a CSV file first");
			return;
		}

		const formData = new FormData();
		formData.append('csv', csvFile);

		try {
			await axios.post(`${BACKEND_URL}/products/import`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
					token: localStorage.getItem("Token"),
				},
			});
			toast.success("CSV uploaded successfully!");
			GetAllProducts()
			setOpenModel(!openModel)
			setCsvFile("")

			// refreshCategories(); // Refresh the categories list after upload
		} catch (error) {
			toast.error("Failed to upload CSV");
		}
	};
	const handlePageChange = (event, value) => {
		setPageNo(value);
	};
	const handleCategoryChange = (event) => {
		const category = event.target.value;
		setSelectedCategory(category);

		if (category === "") {
			setProducts(AllProducts); // Reset to all products if no category is selected
		} else {
			const filteredProducts = AllProducts.filter(
				(product) => product.category == category
			);
			console.log(category, filteredProducts);
			setProducts(filteredProducts);
		}
	};
	return (
		<>
			<Header />
			{/* Page content */}
			<Container
				maxWidth={false}
				component={Box}
				marginTop="-6rem"
				classes={{ root: classes.containerRoot }}
			>
				<Card classes={{ root: classes.cardRoot }}>
					<CardHeader
						className={classes.cardHeader}
						title="Products"
						titleTypographyProps={{
							component: Box,
							marginBottom: "0!important",
							variant: "h3"
						}}
						action={
							<Box display="flex" alignItems="center">
								<FormControl style={{ marginRight: "10px", minWidth: 200 }}>
									<InputLabel id="category-select-label">Category</InputLabel>
									<Select
										labelId="category-select-label"
										id="category-select"
										value={selectedCategory}
										onChange={handleCategoryChange}
									>
										<MenuItem value="">
											<em>All Categories</em>
										</MenuItem>
										{AllCategory?.map((category) => (
											<MenuItem key={category.id} value={category.id}>
												{category.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<IconButton
									aria-label="settings"
									style={{ background: "blue" }}
									onClick={() => history.push("/admin/addProduct/New")}
								>
									<Add style={{ width: "30px", height: "30px", color: "#FFF" }} />
								</IconButton>
								<Button variant="outlined" style={{ marginLeft: "10px" }} onClick={() => setOpenModel(!openModel)}>
									Import CSV
								</Button>
							</Box>
						}
					></CardHeader>
					{products?.length >= 1 ? (
						<TableContainer>
							<Box component={Table} alignItems="center" marginBottom="0!important">
								<TableHead>
									<TableRow>
										<TableCell
											classes={{
												root: classes.tableCellRoot + " " + classes.tableCellRootHead
											}}
										>
											Name
										</TableCell>
										<TableCell
											classes={{
												root: classes.tableCellRoot + " " + classes.tableCellRootHead
											}}
										>
											Price
										</TableCell>
										<TableCell
											classes={{
												root: classes.tableCellRoot + " " + classes.tableCellRootHead
											}}
										>
											Category
										</TableCell>
										{/* <TableCell
											classes={{
												root: classes.tableCellRoot + " " + classes.tableCellRootHead
											}}
										>
											Sub Category
										</TableCell> */}
										<TableCell
											classes={{
												root: classes.tableCellRoot + " " + classes.tableCellRootHead
											}}
										>
											Tags
										</TableCell>
										<TableCell
											classes={{
												root: classes.tableCellRoot + " " + classes.tableCellRootHead
											}}
										>
											Action
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{/* <InfiniteScroll
										dataLength={products?.length}
										next={getMoreProducts}
										hasMore={pagination}
										loader={<h4>Loading...</h4>}
									> */}
									{products?.map((product) => {
										return (
											<TableRow key={product?.id}>
												<TableCell
													classes={{
														root: classes.tableCellRoot + " " + classes.tableCellRootBodyHead
													}}
													component="th"
													variant="head"
													scope="row"
												>
													<Box alignItems="center" display="flex">
														<Box
															component={Avatar}
															marginRight="1rem"
															alt={product?.name}
															src={
																product?.images[0]?.startsWith("products/http") || product?.images[0]?.startsWith("products/https") ? product?.images[0]?.replace("products/", "") : `https://backend.desertsdropshipper.com/${product?.images[0]}`
															}
														/>
														<Box display="flex" alignItems="flex-start">
															<Box fontSize=".875rem" component="span">
																{product?.title || "-"}
															</Box>
														</Box>
													</Box>
												</TableCell>
												<TableCell classes={{ root: classes.tableCellRoot }}>
													{product?.price || 0} Aed
												</TableCell>
												<TableCell classes={{ root: classes.tableCellRoot }}>
													<Box paddingTop=".35rem" paddingBottom=".35rem">
														{product?.category || "-"}
													</Box>
												</TableCell>
												<TableCell classes={{ root: classes.tableCellRoot }}>
													<Box display="flex" alignItems="center">
														<Box component="span" marginRight=".5rem">
															{product?.tag || "-"}
														</Box>
													</Box>
												</TableCell>

												<TableCell classes={{ root: classes.tableCellRoot }}>
													<Box display="flex" alignItems="center">
														<Box
															component="span"
															marginRight=".5rem"
															style={{ cursor: "pointer" }}
															onClick={() => history.push(`/admin/addProduct?id=${product?.id}`)}
														>
															<Edit style={{ width: "20px", height: "20px" }} />
														</Box>
														<Box
															component="span"
															marginRight=".5rem"
															style={{ cursor: "pointer" }}
															onClick={() =>
																Swal.fire({
																	title: "Are you sure?",
																	text: "You won't be able to revert this!",
																	icon: "warning",
																	showCancelButton: true,
																	confirmButtonColor: "#3085d6",
																	cancelButtonColor: "#d33",
																	confirmButtonText: "Yes, delete it!",
																	preConfirm: () => {
																		axios
																			.delete(
																				`${BACKEND_URL}/products/${product?.id}`,
																				{
																					headers: {
																						token: localStorage.getItem("Token")
																					}
																				}
																			)
																			.then(() => {
																				GetAllProducts();
																			})
																			.catch((res) => console.log(res));
																	}
																}).then((result) => {
																	if (result.isConfirmed) {
																		Swal.fire(
																			"Deleted!",
																			"Your Product has been deleted.",
																			"success"
																		);
																	}
																})
															}
														>
															<Delete style={{ color: "red", width: "20px", height: "20px" }} />
														</Box>
													</Box>
												</TableCell>
											</TableRow>
										);
									})}
									{/* </InfiniteScroll> */}
								</TableBody>
							</Box>
						</TableContainer>
					) : (
						<div
							style={{
								width: "100%",
								display: "flex",
								alignContent: "center",
								alignItems: "center",
								justifyContent: "center"
							}}
						>
							<h1>No Products to Show</h1>
						</div>
					)}
					<Box
						classes={{ root: classes.cardActionsRoot }}
						component={CardActions}
						justifyContent="flex-end"
					>
						<Pagination
							count={Math.ceil(totalProducts / productsPerPage)}
							page={pageNo}
							onChange={handlePageChange}
							color="primary"
							variant="outlined"
						/>
						{/* <Pagination color="primary" variant="outlined" /> */}
					</Box>
				</Card>
			</Container >
			<Dialog open={openModel} onClose={() => setOpenModel(!openModel)} aria-labelledby="draggable-dialog-title">
				<DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
					Import CSV
				</DialogTitle>
				<DialogContent>
					<Card classes={{ root: classes.cardRoot }}>
						<Box component={CardContent} classes={{ root: classes.ptMd4 }}>
							<input
								accept=".csv"
								style={{ display: 'none' }}
								id="csv-file"
								type="file"
								onChange={handleFileChange}
							/>
							<label htmlFor="csv-file">
								<IconButton color="primary" aria-label="upload CSV" component="span" style={{ fontSize: "20px" }}>
									<CloudUpload style={{ fontSize: "30px" }} fontSize='30px' />
								</IconButton>
							</label>
							<Button onClick={handleUpload} variant="contained" color="primary">
								Submit CSV File
							</Button>
						</Box>
					</Card>
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={() => setOpenModel(!openModel)}>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default withAppContext(ProductsTable);
